import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  CardContent,
  Card as MuiCard,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  FormText,
  FormCheckBox,
  FormButton,
} from "src/components/formControls";
import { useForm, Form, usePermissions, useAuth, useLog } from "src/hooks";
import { CompanyParams } from "src/ts/types";
import useLoading from "src/hooks/useLoading";
import { AddIcon } from "src/components/icons";
import { ICompany } from "src/ts/interfaces/company";
import UserStatus from "./UserStatus";
import SatellitePopup from "../company/components/SatellitePopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ROLES } from "src/constants";
import { ViewList } from "@mui/icons-material";
import { CompanyService } from "src/services";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";
import HeaderPage from "src/components/page/HeaderPage";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: 1,
  unionAffilated: false,
};

function Users() {
  const {
    values: filterValues,
    handleInputChange: handleFilterChange,
    setValues: setFilterValues,
  } = useForm(initialValues, true, []);
  const { log } = useLog();
  const [valueTab, setValueTab] = useState(0);
  const [companyData, setCompanyData] = useState<ICompany>();
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [satelliteUser, setSatelliteUser] = useState<ICompany>();
  const { isLoading } = useLoading();
  let { id } = useParams<CompanyParams>();
  const companyId = Number(id);
  let navigate = useNavigate();
  const { user } = useAuth();
  const { fullAccess: companyUsersFullAccess } = usePermissions(
    PermissionTypes.Users
  );
  const canAddUsers =
    companyUsersFullAccess &&
    (user?.role === ROLES.SysAdmin ||
      user?.role === ROLES.SuperAdministrator ||
      user?.role === ROLES.Administrator ||
      user?.role === ROLES.CompanyOwner ||
      user?.role === ROLES.Architect);

  const canViewSatelliteUsers =
    user?.role === ROLES.CompanyOwner ||
    user?.role === ROLES.SysAdmin ||
    user?.role === ROLES.SuperAdministrator ||
    user?.role === ROLES.Administrator;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
    setFilterValues({ ...filterValues, statusId: newValue });
  };

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await CompanyService.getById(Number(companyId) ?? 0);
        setCompanyData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (Number(companyId) !== 0) getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      <HeaderPage
        title={"Users"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Stack direction="row" justifyContent="flex-end" mr={10} spacing={5}>
            {canAddUsers && (
              <FormButton
                text="Add User"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate(`/app/usersModify/${companyId}`);
                }}
                size="small"
              />
            )}
            {canViewSatelliteUsers &&
            (satelliteUser?.satelliteCount ?? -1 > 0) ? (
              <FormButton
                text="View Satellite's Users"
                onClick={showSatelliteHandler}
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<ViewList />}
              />
            ) : null}
          </Stack>
        }
        infoSection={
          companyData &&
          !companyData?.headquarters && (
            <CompanyInformation company={companyData} />
          )
        }
        pageYoutube="Users"
      ></HeaderPage>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={10}>
                <FormText
                  name="name"
                  label="Search"
                  value={filterValues.name}
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item xs={2}>
                <FormCheckBox
                  label="Union Affiliated"
                  name="unionAffilated"
                  value={filterValues.unionAffilated}
                  onChange={handleFilterChange}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={6}>
          <Tabs
            value={valueTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Active" id="1" disabled={isLoading} />
            <Tab label="Inactive" id="2" disabled={isLoading} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              {valueTab === 0 ? (
                <UserStatus
                  status="Active"
                  filterValues={filterValues}
                  setSatelliteUser={setSatelliteUser}
                  setCompanyName={() => {}}
                />
              ) : (
                <></>
              )}
              {valueTab === 1 ? (
                <UserStatus
                  status="Inactive"
                  filterValues={filterValues}
                  setSatelliteUser={setSatelliteUser}
                  setCompanyName={() => {}}
                />
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {showSatelliteModal && (
        <SatellitePopup
          showSatellitePopup={showSatelliteModal}
          setShowSatellitePopup={setShowSatelliteModal}
          companyId={companyId}
          companyName={companyData?.name}
          historyView={"Go To"}
        />
      )}
    </>
  );
}

export default Users;
