import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { IBuilding, IPlaqueOrder, IShippingRates } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useCountry,
  useForm,
} from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing } from "@mui/system";
import {
  FormButton,
  FormCancelButton,
  FormCheckBox,
  FormMaskText,
  FormSelect,
  FormSelectState,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import FormBackButton from "src/components/formControls/FormBackButton";
import CartItems from "./components/CartItems";
import { IDeliveryAddress } from "src/ts/interfaces/deliveryAddress";
import { Validator } from "src/ts/types/Validator";
import { COUNTRIES } from "src/constants";
import { buildingService } from "src/services";
import deliverAddressService from "src/services/deliverAddressService";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import FormNextButton from "src/components/formControls/FormNextButton";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import shippingRatesService from "src/services/shippingRatesService";
import plaqueOrderService from "src/services/plaqueOrderService";
import { steps } from "./const/const";
import useZipCode from "src/hooks/useZipCode";
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues: IDeliveryAddress = {
  id: 0,
  projectId: 0,
  guid: "",
  countryId: COUNTRIES.USA,
  address: "",
  address2: "",
  city: "",
  stateName: "",
  zip: "",
  contactName: "",
  email: "",
  phone: "",
  additional: "",
  isDefault: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  countryCode: "1",
  stateId: 0,
};

const columns = (): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "total",
    label: "Total",
    type: "currency",
    sort: true,
  },
];

const DeliveryAddress = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  let { projectId, page } = useParams<{ projectId: string; page: string }>();
  const { countries, countriesKeyValue } = useCountry();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [approveCheck, setApproveCheck] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [refreshData, setRefreshData] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The address is required.")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("Contact's phone number is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "Country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("Zipcode is required.")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.City = new Validator(fieldValues, "City")
      .isRequired("City is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, setValues, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { execute, isSubmitting } = useAsyncMutation<IBuilding>(
    buildingService.getAddress,
    {
      successfulMessage: "The Building Address was applied",
      onSuccess: (result) => {
        const code = countries.filter((item: any) => {
          return item.id === values.countryId;
        });
        result.countryCode = code[0].countryCode;
        result.additional = "";
        setValues({ ...result, phone: result.buildingContact.phone });
      },
    }
  );

  const { execute: executeAddress, data: dataAddress } =
    useAsyncQuery<IBuilding>(buildingService.getAddress);

  const {
    execute: executeCalc,
    data,
    isLoading: isSubmittingCalc,
    setData,
  } = useAsyncQuery<IShippingRates[]>(deliverAddressService.calculate);

  const { execute: executeRate } = useAsyncQuery(
    shippingRatesService.saveRate,
    {
      successfulMessage: "Rate was selected",
      onSuccess: () => {
        setRefreshData(!refreshData);
      },
    }
  );

  const { execute: executeOrder } = useAsyncQuery<IPlaqueOrder>(
    plaqueOrderService.getByProjectId,
    {
      onSuccess: (result) => {
        setData(result.shippingRates);
        if (result.deliverAddress) setValues(result.deliverAddress);
        setSelected(
          Object.values(result.shippingRates ?? [])
            .filter((obj: any) => obj.isSelected)
            .map((item: any) => item.id)
        );
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeOrder(projectId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const code = countries.filter((item: any) => {
      return item.id === values?.countryId;
    });
    if (code.length > 0) {
      setValues({
        ...values,
        countryCode: code[0].countryCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.countryId, countries, values?.country]);

  const { zipValue } = useZipCode(values?.zip);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      if (values.stateId !== zipValue.stateId) setIsStateLoading(true);
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  const handleCancel = () => {
    if (page === "plaqueCompanyOrder") navigate(`/app/plaqueCompanyOrders`);
    else navigate(`/app/ProjectList/ApprovedByAMP/${user?.companyId}`);
  };

  const handleBack = () => {
    if (page === "plaqueCompanyOrder")
      navigate(`/app/selectPlaque/${projectId}/2/plaqueCompanyOrder`);
    else navigate(`/app/selectPlaque/${projectId}/2/deliveryAddress`);
  };

  const handleUseBuildingAddress = async () => {
    await execute(projectId);
  };

  const handleCalculate = async () => {
    if (!validate()) return;
    values.projectId = projectId;
    await executeCalc(values);
  };

  const selectShipment = data && data.length > 0;

  const handleChangeAddress = () => {
    setData([]);
  };

  const handleApprove = async () => {
    await executeAddress(projectId);
    setConfirmModal(true);
  };

  const handleConfirm = () => {
    if (page === "plaqueCompanyOrder")
      navigate(`/app/completeOrder/${projectId}/plaqueCompanyOrder`);
    else navigate(`/app/completeOrder/${projectId}/deliveryAddress`);
  };

  const handleSelect = async (selects: number[]) => {
    if (JSON.stringify(selects) !== JSON.stringify(selected)) {
      setSelected(selects);
      if (selects.length > 0) {
        await executeRate(selects[0]);
      }
    }
  };

  const mode = selectShipment ? "read" : "read&Write";

  return (
    <>
      <HeaderPage
        title={"Order Plaque"}
        parentText={"Project"}
        parentLink={`/app/ProjectList/${user?.companyId}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Stepper alternativeLabel activeStep={2}>
            {steps.map((step) => (
              <Step key={step.key}>
                <StepLabel>
                  {step.label}
                  <br /> {step.description}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={mode === "read" ? 1 : 5}>
                <Grid item xs={6}>
                  <Typography variant="h5">Shipping address</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" textAlign={"right"}>
                    {!selectShipment && (
                      <FormButton
                        text={"Use Building Address"}
                        onClick={handleUseBuildingAddress}
                        isSubmitting={isSubmitting || isSubmittingCalc}
                        size="small"
                        variant="outlined"
                        mode={mode}
                      ></FormButton>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name={"address"}
                    value={values?.address}
                    label={"Address 1"}
                    error={errors.address}
                    onChange={handleInputChange}
                    mode={mode}
                  ></FormText>
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name={"address2"}
                    value={values?.address2}
                    label={"Address 2"}
                    error={errors.address2}
                    onChange={handleInputChange}
                    mode={mode}
                  ></FormText>
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    name="countryId"
                    label="Country"
                    value={values?.countryId}
                    onChange={handleInputChange}
                    options={countriesKeyValue}
                    error={errors.countryId}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="zip"
                    label="Zip Code"
                    value={values?.zip}
                    onChange={handleInputChange}
                    error={errors.zip}
                    maxLength={6}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="city"
                    label="City"
                    value={values?.city}
                    onChange={handleInputChange}
                    error={errors.city}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelectState
                    stateId={values?.stateId}
                    stateName={values?.stateName}
                    countryId={values?.countryId}
                    errorsStateId={errors.stateId}
                    errorsStateName={errors.stateName}
                    onChange={handleInputChange}
                    isLoading={(value: boolean) => setIsStateLoading(value)}
                    mode={mode}
                    showSkeleton={isSubmitting}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormText
                    name="countryCode"
                    label="Code"
                    value={"+ " + values?.countryCode}
                    disabled={true}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormMaskText
                    name="phone"
                    mask="(999) 999 - 9999"
                    label="Contact's Phone Number"
                    onChange={handleInputChange}
                    value={values?.phone}
                    error={errors.phone}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextArea
                    name={"additional"}
                    label={"Additional Information"}
                    value={values?.additional}
                    onChange={handleInputChange}
                    mode={mode}
                  ></FormTextArea>
                </Grid>
              </Grid>
              <Divider my={6} />

              {selectShipment && (
                <Grid textAlign={"center"}>
                  <FormButton
                    text={"Change Address"}
                    onClick={handleChangeAddress}
                    size="small"
                    isSubmitting={isSubmittingCalc}
                  ></FormButton>
                </Grid>
              )}
              {selectShipment && (
                <Grid container>
                  <Grid item xs={12} pb={5}>
                    <Typography variant="h5">Select Shipment</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalEnhancedTable<IShippingRates>
                      refreshGrid={true}
                      columns={columns()}
                      data={Object.values(data ?? [])}
                      cellCheckBox={true}
                      onSelect={handleSelect}
                      selectedItemsId={selected}
                      onlyOneSelected={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Plaques</Typography>
                    <Typography>
                      Estimated time of manufacturing: 3 or 4 weeks.
                    </Typography>
                    <Typography>Estimated time of delivery: 1 week.</Typography>
                    <Typography>
                      NOTE: The time of delivery starts when the manufacturing
                      of the Plaque is finished.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={5}>
                    <Typography variant="subtitle2">Decals</Typography>
                    <Typography>
                      Estimated time of manufacturing: 1 week.
                    </Typography>
                    <Typography>Estimated time of delivery: 1 week.</Typography>
                    <Typography>
                      NOTE:The time of delivery starts when the manufacturing of
                      the Decals is finished.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={5}>
                    <FormCheckBox
                      name={"acceptTerms"}
                      label={"I accept the Shipment Delivery Terms"}
                      value={acceptTerms}
                      onChange={() => {
                        setAcceptTerms(!acceptTerms);
                      }}
                    ></FormCheckBox>
                  </Grid>
                </Grid>
              )}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                pt={5}
              >
                <FormBackButton
                  onClick={handleBack}
                  isSubmitting={isSubmittingCalc}
                ></FormBackButton>
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={isSubmittingCalc}
                ></FormCancelButton>
                {!selectShipment && (
                  <FormButton
                    text={"Calculate Shipping Cost"}
                    onClick={handleCalculate}
                    size="small"
                    isSubmitting={isSubmittingCalc}
                  ></FormButton>
                )}
                {acceptTerms && selected && selected.length === 1 && (
                  <FormNextButton
                    onClick={handleApprove}
                    isSubmitting={false}
                  ></FormNextButton>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <CartItems
            projectId={projectId ?? ""}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          ></CartItems>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={
          "Entered for your Building Plaque, Decals, and Certification is:"
        }
        text={
          <>
            <Typography variant={"h5"} pb={3}>
              The following building name and address:
            </Typography>
            <Typography variant={"h6"} pb={3}>
              Photograph Building
            </Typography>
            <Typography>
              <Typography variant="subtitle2" display={"inline-flex"} pb={3}>
                Located at:&nbsp;
              </Typography>
              <Typography display={"inline-flex"} pb={3}>
                {`${dataAddress?.address}, ${dataAddress?.address2 ?? ""}${
                  dataAddress?.address2 ? "," : ""
                } ${dataAddress?.city},
                ${dataAddress?.zip}`}
              </Typography>
              <Typography pb={3}>
                The above listed Name & Address will be used in your plaque once
                you approve all Spelling and the projects Address.
              </Typography>
              <Typography pb={3}>
                Please validate all data is correct and make any required
                changes/modifications necessary in the building's list.
              </Typography>
            </Typography>
            <FormCheckBox
              name={"approveCheck"}
              value={approveCheck}
              onChange={() => {
                setApproveCheck(!approveCheck);
              }}
              label={"I approve the information provided above."}
            ></FormCheckBox>
          </>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        onSave={handleConfirm}
        isSubmitting={false}
        disabled={!approveCheck}
      ></DialogMessagePopup>
    </>
  );
};

export default DeliveryAddress;
