import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip as MuiChip,
} from "@mui/material";
import { ICompany, ICoupon, IPlaqueOrder } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncMutation, useAsyncQuery, useAuth, useLog } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing, SpacingProps } from "@mui/system";
import {
  FormButton,
  FormCancelButton,
  FormCheckBox,
} from "src/components/formControls";
import FormBackButton from "src/components/formControls/FormBackButton";
import plaqueOrderService from "src/services/plaqueOrderService";
import PurchaseSummary from "../Cart/PurchaseSummary";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { PlaqueOrderStatus } from "src/constants";
import { steps } from "./const/const";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { currencyformatter } from "src/utils/format";
import WalletService from "src/services/walletService";
import { CompanyService } from "src/services";
import FormTextHideText from "src/components/formControls/FormTextHideText";
const Divider = styled(MuiDivider)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Card = styled(MuiCard)(spacing);
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Chip = styled(MuiChip)<ChipProps>(spacing);
const CompleteOrder = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  let { projectId } = useParams<{ projectId: string }>();
  let { page } = useParams<{ page: string }>();
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [confirmCartModal, setConfirmCartModal] = useState(false);
  const [approveCheck, setApproveCheck] = useState(false);
  const [sendToCart, setSendToCart] = useState(false);
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [code, setCode] = useState<string>("");
  const [completeOrderModal, setCompleteOrderModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const { execute, data, setData } = useAsyncQuery<IPlaqueOrder>(
    plaqueOrderService.getByProjectId
  );
  const { execute: executeCode } = useAsyncMutation<IPlaqueOrder>(
    plaqueOrderService.previewOrder,
    {
      onSuccess: async (response) => {
        setData(response);
        setCode("");
        if (response.discount === 0) {
          log.error("The code is invalid");
        } else log.success("Code applied");
      },
      hideSuccessfulMessage: true,
    }
  );
  const { execute: executePurchase, isSubmitting } =
    useAsyncMutation<IPlaqueOrder>(plaqueOrderService.purchase, {
      onSuccess: async (result) => {
        log.success(result.resultMessage);
        if (sendToCart)
          setRefreshData(!refreshData); //await execute(projectId);
        else navigate(`/app/viewOrder/${data.id}/completeOrder`);
      },
      hideSuccessfulMessage: true,
    });

  const { log } = useLog();
  const { execute: executeWalletBalance, data: walletBalance } =
    useAsyncQuery<number>(WalletService.getWalletBalance);

  const [companyInfo, setCompanyInfo] = useState<ICompany>();

  useEffect(() => {
    const getData = async () => {
      await execute(projectId);
      if (user?.companyId !== 0) {
        await executeWalletBalance(user?.companyId);
        const response = await CompanyService.getById(user?.companyId);
        setCompanyInfo(response.data);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refreshData]);

  const handleCancel = () => {
    if (page === "plaqueCompanyOrder") navigate(`/app/plaqueCompanyOrders`);
    else navigate(`/app/ProjectList/ApprovedByAMP/${user?.companyId}`);
  };

  const handleBack = () => {
    navigate(`/app/selectPlaque/${projectId}/2/completeOrder`);
  };

  const handlePurchase = (sentCart: boolean) => {
    setApproveCheck(false);
    setCompleteOrderModal(true);
    setSendToCart(sentCart);
  };

  const handleDeleteOrder = () => {
    setConfirmDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await plaqueOrderService.cancelOrder(Number(projectId));
    log.success("Order cancelled");
    navigate(`/app/ProjectList/ApprovedByAMP/${user?.companyId}`);
  };

  const handleCompleteOrder = async () => {
    await executePurchase(projectId, sendToCart);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCode(value);
  };

  const handleApplyCoupon = async () => {
    await executeCode(projectId, code);
  };

  return (
    <>
      <HeaderPage
        title={"Order Plaque"}
        parentText={"Project"}
        parentLink={`/app/ProjectList/${user?.companyId}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Stepper alternativeLabel activeStep={3}>
            {steps.map((step) => (
              <Step key={step.key}>
                <StepLabel>
                  {step.label}
                  <br /> {step.description}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      <Grid container spacing={6}>
        <Grid item xs={data && data.projectPurchaseId === null ? 8 : 12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Shadow>
                    <Card px={6} pt={6}>
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} textAlign={"right"}>
                            <Typography variant="h2">
                              Order No. {data?.id}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="subtitle2" gutterBottom>
                              Delivery Address:
                            </Typography>
                            <Typography>
                              {data?.deliverAddress?.address},{" "}
                              {data?.deliverAddress?.address2}
                            </Typography>
                            <Typography>
                              {data?.deliverAddress?.state?.name},{" "}
                              {data?.deliverAddress?.city}{" "}
                              {data?.deliverAddress?.country?.name}{" "}
                              {data?.deliverAddress?.zip}
                            </Typography>
                            <Typography>
                              +{data?.deliverAddress?.country?.countryCode}{" "}
                              {data?.deliverAddress?.phone}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} textAlign={"right"}>
                            {data?.orderStatus ===
                              PlaqueOrderStatus.Pending && (
                              <FormButton
                                text={"Delete Order"}
                                onClick={handleDeleteOrder}
                                variant={"outlined"}
                                color={"error"}
                                size="small"
                              ></FormButton>
                            )}
                          </Grid>
                          <Grid item xs={12} alignItems={"right"}>
                            <Typography
                              variant="caption"
                              align="right"
                              display="block"
                            >
                              Order Date
                            </Typography>
                            <Typography variant="body2" align="right">
                              {convertUTCDateToLocalDate(
                                data?.createdDate ?? ""
                              )?.format("MM/DD/yyyy")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="caption">Status</Typography>
                            <Typography variant="body2">
                              {data?.orderStatus === PlaqueOrderStatus.Paid
                                ? "Paid"
                                : "Pending"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="caption"
                              align="right"
                              display="block"
                            >
                              Additional Information
                            </Typography>
                            <Typography variant="body2" align="right">
                              {data?.deliverAddress?.additional}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card px={6}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="right">Unit Price</TableCell>
                            <TableCell align="right">Sub-Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data &&
                            data?.plaqueOrderDetail.map((item) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {item.plaques.name}
                                </TableCell>
                                <TableCell align="center">
                                  {item.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  {currencyformatter.format(item.price)}
                                </TableCell>
                                <TableCell align="right">
                                  {currencyformatter.format(item.price)}
                                </TableCell>
                              </TableRow>
                            ))}
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">Subtotal</TableCell>
                            <TableCell align="right">
                              {currencyformatter.format(
                                data?.total + data?.discount - data?.shipping
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">Shipping</TableCell>
                            <TableCell align="right">
                              {currencyformatter.format(data?.shipping)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">Discount</TableCell>
                            <TableCell align="right">
                              {currencyformatter.format(data?.discount)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right">
                              {currencyformatter.format(data?.total)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Card>
                  </Shadow>
                </Grid>
              </Grid>
              <Divider my={6} />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                pt={5}
              >
                {data && data.projectPurchaseId === null && (
                  <FormBackButton
                    onClick={handleBack}
                    isSubmitting={false}
                  ></FormBackButton>
                )}
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={false}
                ></FormCancelButton>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <>
          {data && data.projectPurchaseId === null && (
            <Grid item xs={4}>
              <Card mb={6}>
                <CardContent>
                  <PurchaseSummary
                    companyId={user?.companyId}
                    total={data?.total ?? 0}
                  ></PurchaseSummary>
                </CardContent>
              </Card>
              <Card mb={6}>
                <CardContent>
                  <Grid container pb={5}>
                    <Grid item direction="row" xs={12}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={3}
                      >
                        {coupon !== null && (
                          <Chip
                            label={`${coupon?.value}% OFF`}
                            color="success"
                            m={1}
                            size="medium"
                          />
                        )}
                        <FormTextHideText
                          name="code"
                          label={"Enter your code"}
                          value={code}
                          onChange={handleInputChange}
                        />

                        <FormButton
                          text="Apply Coupon"
                          size="small"
                          onClick={handleApplyCoupon}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    {companyInfo &&
                      ((walletBalance - (data?.total ?? 0) > 0 &&
                        !companyInfo?.walletBlocked &&
                        !companyInfo?.walletAdminBlocked) ||
                        companyInfo?.walletAutoWithdraw) && (
                        <Grid item xs={6}>
                          <FormButton
                            text={"Purchase"}
                            onClick={() => {
                              handlePurchase(false);
                            }}
                            fullWidth={true}
                            size="small"
                          ></FormButton>
                        </Grid>
                      )}
                    <Grid
                      item
                      xs={
                        (walletBalance - (data?.total ?? 0) > 0 &&
                          !companyInfo?.walletBlocked &&
                          !companyInfo?.walletAdminBlocked) ||
                        companyInfo?.walletAutoWithdraw
                          ? 6
                          : 12
                      }
                    >
                      <FormButton
                        text={"Send to Cart"}
                        onClick={() => {
                          handlePurchase(true);
                        }}
                        variant={"outlined"}
                        fullWidth={true}
                        size="small"
                      ></FormButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      </Grid>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={confirmDeleteModal}
        setIsDialogOpen={setConfirmDeleteModal}
        text={"Are you certain you want to delete this order?"}
        onConfirm={handleConfirmDelete}
      ></MatchWordConfirmModal>
      <DialogMessagePopup
        title={"Information"}
        text={
          <>
            <Typography pb={3}>
              When you complete your order purchase plaque your order goes
              directly to the foundry manufacturer. You’ll be asked twice to
              validate if you are certain of your decision to purchase your
              plaque(s).
            </Typography>
            <Typography pb={3}>
              Once your commitment is made to move forward for the second time
              and you confirm your purchase, we won’t be able to provide any
              refunds due to the fact the manufacturer has been given your order
              directly and requested to immediately begin fabricating your
              plaque(s).
            </Typography>
            <Typography pb={3}>
              This is also true when purchasing any door and window decals. AMP
              has the same manufacturer making the Bronze, Acrylic and Glass
              plaques.
            </Typography>
            <Typography pb={3}>
              Your buildings digital certification will arrive within 72 hours
              via email and will be available for download.
            </Typography>
            <FormCheckBox
              name={"approveCheck"}
              value={approveCheck}
              onChange={() => {
                setApproveCheck(!approveCheck);
              }}
              label={"I accept conditions described above."}
            ></FormCheckBox>
          </>
        }
        showPopup={completeOrderModal}
        setShowPopup={setCompleteOrderModal}
        onSave={() => {
          setConfirmCartModal(true);
        }}
        isSubmitting={isSubmitting}
        disabled={!approveCheck}
      ></DialogMessagePopup>

      <DialogMessagePopup
        title={"Warning"}
        text={`
          Are you certain you want to complete your order and ${
            sendToCart ? "send it to cart?" : "process the payment?"
          }
          `}
        showPopup={confirmCartModal}
        setShowPopup={setConfirmCartModal}
        isSubmitting={isSubmitting}
        onSave={handleCompleteOrder}
        hideCancel={isSubmitting}
        cancelTextButton="Cancel"
      />
    </>
  );
};

export default CompleteOrder;
