import React from "react";
import { RoleEnum } from "./ts/enums";
import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import PageInvalidPermissions from "./pages/auth/PageInvalidPermissions";

//AMP Pages
import Companies from "./pages/company/Companies";
import Administrators from "./pages/users/Administrators";
import Administrator from "./pages/users/Administrator";
import SystemAdministratorsPage from "./pages/SystemAdministratorsPage/SystemAdministratorsPage";
import Coupons from "./pages/coupons/Coupons";
import ProjectsOutForBid from "./pages/projectOutForBid/ProjectsOutForBid";
import ProjectsOutForBidInformation from "./pages/projectOutForBid/ProjectOutForBidInformation";
import SysAdminDocuments from "./pages/sysAdminDocuments/SysAdminDocuments";
import Catalog from "./pages/catalogs/Catalog";
import Quizs from "./pages/quiz/Quizs";
import Quiz from "./pages/quiz/Quiz";
import CompanyDocumentPage from "./pages/company/CompanyDocumentPage";
import Users from "./pages/users/Users";
import User from "./pages/users/User";
import SubscriptionCost from "./pages/SubscriptionCost/SubscriptionCost";
import Units from "./pages/catalogs/units/Units";
import BuildingSubtype from "./pages/catalogs/buildingSubtype/BuildingSubtype";

import StoryBookPage from "./pages/storyBook/StoryBookPage";
//pages without menu access
import NotificationsPage from "./pages/notifications/NotificationsPage";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import QuizView from "./pages/quiz/QuizView";
import Questions from "./pages/quiz/Questions";
import TicketManagment from "./pages/tickets/TicketManagment";
import Orders from "./pages/plaquesAndDecals/orders";
import Providers from "./pages/plaquesAndDecals/providers";
import Provider from "./pages/plaquesAndDecals/providers/Provider";
import PlaqueOrdersModify from "./pages/plaquesAndDecals/orders/PlaqueOrdersModify";
import Plaques from "./pages/plaquesAndDecals/providers/Plaques";
import SubscriptionPricing from "./pages/Subscriptions/SubscriptionPricing";
import SubscriptionDetail from "./pages/Subscriptions/SubscriptionDetail";
import Profile from "./pages/profile";
import EmailBlackList from "./pages/emailBlackList";

import Conversions from "./pages/conversion";
import ElavonPaymentLog from "./pages/Wallet/elavonPaymentLog";
import SystemWalletSummary from "./pages/Wallet/systemWalletSummary";
import NavBarGlossary from "./components/navbar/components/NavBarGlossary";
import History from "./pages/history/History";
import UploadDocuments from "./pages/users/components/UploadDocuments";
import CompanyView from "./pages/company/components/CompanyView";
import ProjectReport from "./pages/project/ProjectReport";
import YoutubeVideosBase from "./pages/catalogs/youtubeTraningVideos/YoutubeVideosBase";
import CouponsHistroy from "./pages/history/CouponsHistroy";
import AddNewUser from "./pages/users/AddNewUser";
import AuditorDashboard from "./pages/dashboards/Auditor";
import SysAdminDashboar from "./pages/dashboards/SysAdministrator";

import { Permission } from "./ts/enums";
import CompanyAssetsType from "./pages/catalogs/CompanyAssetsType/CompanyAssetsType";
import PurchaseHistoryLog from "./pages/Wallet/purchaseHistory";
import Exam from "./pages/quiz/Exam";
import PersonalDocuments from "./pages/personalDocuments/PersonalDocuments";
import Assets from "./pages/companyAssets/Assets";
import Building from "./pages/Buildings/Building";
import ActiveProjects from "./pages/ActiveProjects/ActiveProjects";
import TechnicianCompleteProjects from "./pages/TechnicianCompleteProjects/TechnicianCompleteProjects";
import BuildingPhoto from "./pages/Buildings/BuildingPhoto";
import DocumentLibrary from "./pages/common/DocumentLibrary/DocumentLibrary";
import PictureLog from "./pages/common/photoLibrary/PictureLog";
import TeamMembers from "./pages/teamMembers/TeamMembers";
import TrainingDocuments from "./pages/TrainingDocuments/TrainingDocuments";
import BuildingForm from "./pages/Buildings/BuildingForm";
import AssetsDocumentation from "./pages/companyAssets/components/AssetDocumentation";
import BuildingEnergyCost from "./pages/Buildings/BuildingEnergyCost";
import BuildingLimitedData from "./pages/Buildings/BuildingLimitedData";
import Project from "./pages/project/createEditProject/Project";
import CreateInfectiousControlProject from "./pages/project/createInfectiousControlProject/CreateInfectiousControlProject";
import ProjectStudies from "./pages/project/projectStudies/ProjectStudies";
import ProjectList from "./pages/project/projectList/ProjectList";
import BuildingAnnualDetailed from "./pages/Buildings/BuildingAnnualDetailed";
import BuildingAnnualDetailedData from "./pages/Buildings/BuildingAnnualDetailedData";
import MandatoryMinimum from "./pages/catalogs/mandatoryMinimum/mandatoryMinimum";
import MandatoryMinimumForm from "./pages/catalogs/mandatoryMinimum/mandatoryMinimumForm";
import Env from "src/pages/env";

import TermsAndConditions from "./pages/catalogs/termsAndConditions/termsAndConditions";
import TermsAndConditionsForm from "./pages/catalogs/termsAndConditions/termsAndConditionsForm";
import ArchitectDashboard from "./pages/dashboards/Architect";
import CompanyOwnerDashboard from "./pages/dashboards/CompanyOwner";
import SuperAdministratorDashboard from "./pages/dashboards/SuperAdministrator";
import UnionAdministratorDashboard from "./pages/dashboards/UnionAdministrator";
import TrainerDashboard from "./pages/dashboards/Trainer";
import TrainingDashboard from "./pages/dashboards/Training";
import TechniciansHistory from "./pages/history/TechniciansHistory";
import ProjectHistory from "./pages/history/ProjectHistory";
import StudyHistory from "./pages/history/StudyHistory";
import UserAccessHistory from "./pages/history/UserAccessHistory";
import LocalUnions from "./pages/catalogs/localUnions/LocalUnions";
import ProjectManagerDashboard from "./pages/dashboards/ProjectManager";
import PurchaseStorage from "./pages/storage/PurchaseStorage";
import ProjectDashboard from "./pages/project/projectDashboard";
import AuditorTechnicians from "./pages/auditorsTechnicians/AuditorTechnicians";
import AuditorTechnicianEditAdd from "./pages/auditorsTechnicians/AuditorTechnicianEditAdd";
import TechniciansPermissions from "./pages/technicianPermissions/TechniciansPermissions";
import WalletList from "./pages/Wallet/WalletList";
import CardForm from "./pages/Wallet/CardForm";
//studies
import OutletMaster from "./pages/studies/outletMaster/step1";
import OutletMasterStep2 from "./pages/studies/outletMaster/step2/OutletMasterStep2";
import OutletMasterStep3 from "./pages/studies/outletMaster/step3/OuletMasterStep3";
import OutletStep1 from "./pages/studies/outlet/OutletStep1";
import OutletStep2 from "./pages/studies/outlet/outletStep2";
import OutletStep3 from "./pages/studies/outlet/outletStep3";
import OutletStep5 from "./pages/studies/outlet/OutletStep4";
import VelgridReport from "./pages/studies/velgrid/VelgridMatrix";
import Cart from "./pages/Cart/Cart";
import AuditorTechniciansAssignmentss from "./pages/auditorsTechnicians/AuditorTechniciansAssignmentss";
import MemorySummary from "./pages/storage/MemorySummary";
import ContractorsList from "./pages/contractors/ContractorsList";
import WalletHistory from "./pages/Wallet/WalletHistory";
import SuggestionBankList from "./pages/SuggestionBank/SuggestionBankList";
import SuggestionBankInfo from "./pages/SuggestionBank/SuggestionBankInfo";
import SuggestionBankListAdmin from "./pages/SuggestionBank/SuggestionBankListAdmin";
import NavBarRecycleBin from "./components/navbar/recycleBin/NavBarRecycleBin";
import ProjectsOutForBidAdmin from "./pages/projectOutForBid/ProjectsOutForBidAdmin";
import TemporalProjectContest from "./pages/project/TemporalProjectContest";
import ProjectTemplate from "./pages/project/Template/ProjectTemplate";
import ProjectTolerances from "./pages/project/Template/ProjectTolerances";
import ProjectTemplateStudies from "./pages/project/Template/ProjectTemplateStudies";
import VelgridStep3 from "./pages/studies/velgrid/VelgridStep3";
import GeneralMatrix from "./pages/studies/generalMatrix/GeneralMatrix";
import GeneralMatrixStep3 from "./pages/studies/generalMatrix/GeneralMatrixStep3";
import PitotFanTraverse from "./pages/studies/pitot/PitotFanTraverse";
import PitotFanTraverseStep3 from "./pages/studies/pitot/PitotFanTraverseStep3";
import ElectricCoilStep1 from "./pages/studies/electricCoil/ElectricCoilStep1";
import ElectricCoilStep2 from "./pages/studies/electricCoil/ElectricCoilStep2";
import ElectricCoilStep3 from "./pages/studies/electricCoil/ElectricCoilStep3";
import SelectPlaques from "./pages/orderPlaque/SelectPlaques";
import DeliveryAddress from "./pages/orderPlaque/DeliveryAddress";
import CompleteOrder from "./pages/orderPlaque/CompleteOrder";
import StaticStep1 from "./pages/studies/static/step1";
import StaticStep2 from "./pages/studies/static/step2";
import StaticStep3 from "./pages/studies/static/step3";
import AddUpdateEquipmentVehicle from "./pages/companyAssets/AddUpdateEquipmentVehicle";
import BoilerTest from "./pages/studies/boiler/BoilerTest";
import BoilerStep5 from "./pages/studies/boiler/BoilerStep5";
import CoilsStep1 from "./pages/studies/coils/CoilsStep1";
import CoilsStep2 from "./pages/studies/coils/CoilsStep2";
import CoilsStep3 from "./pages/studies/coils/CoilsStep3";
import CoilsStep4 from "./pages/studies/coils/CoilsStep4";
import ElectricCoilStep4 from "./pages/studies/electricCoil/ElectricCoilStep4";
import TowerStep1 from "./pages/studies/tower/TowerStep1";
import TowerStep2 from "./pages/studies/tower/towerStep2/Index";
import TowerStep3 from "./pages/studies/tower/TowerStep3";
import TowerStep31 from "./pages/studies/tower/towerStep31";
import TowerStep4 from "./pages/studies/tower/towerStep4";
import TowerStep5 from "./pages/studies/tower/towerStep5";
import TowerStep6 from "./pages/studies/tower/towerStep6/";
import TowerStep7 from "./pages/studies/tower/TowerStep7";
import ChillerStep1 from "./pages/studies/chiller/step1";
import ChillerStep2 from "./pages/studies/chiller/step2";
import ChillerStep3 from "./pages/studies/chiller/step3";
import ChillerStep31 from "./pages/studies/chiller/step31";
import ChillerStep4 from "./pages/studies/chiller/step4";
import ChillerStep41 from "./pages/studies/chiller/step41";
import ChillerStep42 from "./pages/studies/chiller/step42";
import ChillerStep5 from "./pages/studies/chiller/step5";
import ChillerStep6 from "./pages/studies/chiller/step6";
import ChillerStep7 from "./pages/studies/chiller/step7";
import ChillerStep8 from "./pages/studies/chiller/step8";
import ProjectsArchitect from "./pages/project/ProjectsArchitect";
import ProjectWizard from "./pages/project/componentsArchitectProject/componentsCreateProject/ProjectWizard";
import ToleranceWizard from "./pages/project/componentsArchitectProject/componentsCreateProject/ToleranceWizard";
import StudiesWizard from "./pages/project/componentsArchitectProject/componentsCreateProject/StudiesWizard";
import ConfigurationWizard from "./pages/project/componentsArchitectProject/componentsCreateProject/ConfigurationWizard";
import ProjectOutForBidWizard from "./pages/project/componentsArchitectProject/componentsCreateProject/ProjectOutForBidWizard";
import ViewTemplateFromTolerances from "./pages/project/ViewTemplateFromTolerances";
import PlaqueCompanyOrders from "./pages/project/plaqueCompanyOrders/PlaqueCompanyOrders";
import ContactConfirmation from "./pages/contactInformation/ContactConfirmation";
import ContactInformationMain from "./pages/contactInformation/ContactInformationMain";
import ForwardDocumentLibrary from "./pages/history/ForwardDocumentLibrary";
import ProjectBidsHistory from "./pages/history/ProjectBidsHistory";
import Dispute from "./pages/dispute/Dispute";
import SampleReports from "./pages/sampleReports/SampleReports";
import ProjectEvaluation from "./pages/project/projectEvaluation/ProjectEvaluation";
import ProjectInfectiousControlArchitect from "./pages/project/ProjectInfectiousControlArchitect";
import HeadSheetStep1 from "./pages/studies/headsheet/step1";
import HeadSheetStep2 from "./pages/studies/headsheet/step2";
import HeadSheetStep3 from "./pages/studies/headsheet/step3";
import HeadSheetStep4 from "./pages/studies/headsheet/step4";
import HeadSheetStep5 from "./pages/studies/headsheet/step5";
import HeadSheetStep6 from "./pages/studies/headsheet/step6";
import PumpStep1 from "./pages/studies/pump/step1";
import PumpStep2 from "./pages/studies/pump/step2";
import PumpStep3 from "./pages/studies/pump/step3";
import PumpStep4 from "./pages/studies/pump/step4";
import PumpStep5 from "./pages/studies/pump/step5";
import PumpStep6 from "./pages/studies/pump/step6";
import ConfirmTeamMember from "./pages/teamMembers/ConfirmTeamMember";
import ResetPasswordUser from "./pages/resetPassword/ResetPassword";
import NewAccountPassword from "./components/auth/NewAccountPassword";
import UpgradeSubscription from "./pages/dashboards/Training/UpgradeSubscription";
import RequestTransferCompanyAssets from "./pages/companyAssets/RequestTransferCompanyAssets";
import RequestMemorandum from "./pages/memorandum/RequestMemorandum";
import RequestWorkOrder from "./pages/workOrder/RequestWorkOrder";
import GetDocument from "./pages/sharedDocument/GetDocument";
import TechnicianTransferRequest from "./pages/technicianTransferRequest/TechnicianTransferRequest";
import AuthorizeTechnicianPermission from "./pages/authorizeTechnicianPermission/AuthorizeTechnicianPermission";
import Unsubscribe from "./pages/unsubscribe/Unsubscribe";
import RatingContractorNotification from "./pages/ratingContractorNotification/RatingContractorNotification";
import EnergyHydronicsStep1 from "./pages/studies/energyHydronics/step1";
import EnergyHydronicsStep2 from "./pages/studies/energyHydronics/step2";
import EnergyHydronicsStep3 from "./pages/studies/energyHydronics/step3";
import EnergyHydronicsStep4 from "./pages/studies/energyHydronics/step4";
import EnergyHydronicsStep5 from "./pages/studies/energyHydronics/step5";
import EnergyHydronicsStep6 from "./pages/studies/energyHydronics/step6";
import EnergyHydronicsStep7 from "./pages/studies/energyHydronics/step7";
import MyPendingRequests from "./pages/myPendingRequest/MyPendingRequests";
import Testimonials from "./pages/testimonials/Testimonials";
import SurveyProject from "./pages/SurveyProject/SurveyProject";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Company = async(() => import("./pages/company/Company"));

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/env",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Env />,
      },
    ],
  },
  {
    path: "studies",
    element: <DashboardLayout />,
    children: [
      {
        path: "OutletMaster/:id",
        element: <OutletMaster />,
      },
      {
        path: "OutletMasterStep2/:id",
        element: <OutletMasterStep2 />,
      },
      {
        path: "OutletMasterStep3/:id",
        element: <OutletMasterStep3 />,
      },
      {
        path: "VelgridMatrix/step3/:id",
        element: <VelgridReport />,
      },
      {
        path: "VelgridMatrix/:step/:id",
        element: <VelgridReport />,
      },
      {
        path: "cav/step1/:id",
        element: <OutletStep1 />,
      },
      {
        path: "cav/step2/:id",
        element: <OutletStep2 />,
      },
      {
        path: "cav/step3/:id",
        element: <OutletStep3 />,
      },
      {
        path: "cav/step5/:id",
        element: <OutletStep5 />,
      },
      //
      {
        path: "vav/step1/:id",
        element: <OutletStep1 />,
      },
      {
        path: "vav/step2/:id",
        element: <OutletStep2 />,
      },
      {
        path: "vav/step3/:id",
        element: <OutletStep3 />,
      },
      {
        path: "vav/step5/:id",
        element: <OutletStep5 />,
      },
      //
      {
        path: "fpb/step1/:id",
        element: <OutletStep1 />,
      },
      {
        path: "fpb/step2/:id",
        element: <OutletStep2 />,
      },
      {
        path: "fpb/step3/:id",
        element: <OutletStep3 />,
      },
      {
        path: "fpb/step5/:id",
        element: <OutletStep5 />,
      },
      {
        path: "ReportType/Step3/:id/:code",
        element: <GeneralMatrix />,
      },
      {
        path: "ReportType/:step/:id/:code",
        element: <GeneralMatrix />,
      },
      {
        path: "ReportType/:id/:code",
        element: <GeneralMatrix />,
      },
      {
        path: "PitotTraverseStart/Step3/:id",
        element: <PitotFanTraverseStep3 />,
      },
      {
        path: "PitotTraverseStart/:step/:id",
        element: <PitotFanTraverse />,
      },
      {
        path: "PitotTraverseStartTT/Step3/:id",
        element: <PitotFanTraverseStep3 />,
      },
      {
        path: "PitotTraverseStartTT/:step/:id",
        element: <PitotFanTraverse />,
      },
      {
        path: "ElectricCoil/step1/:id",
        element: <ElectricCoilStep1 />,
      },
      {
        path: "ElectricCoil/step2/:id",
        element: <ElectricCoilStep2 />,
      },
      {
        path: "ElectricCoil/step3/:id",
        element: <ElectricCoilStep3 />,
      },
      {
        path: "ElectricCoil/step4/:id",
        element: <ElectricCoilStep4 />,
      },
      {
        path: "Tower/step1/:id",
        element: <TowerStep1 />,
      },
      {
        path: "Tower/step2/:id",
        element: <TowerStep2 />,
      },
      {
        path: "Tower/step3/:id",
        element: <TowerStep3 />,
      },
      {
        path: "Tower/step31/:id",
        element: <TowerStep31 />,
      },
      {
        path: "Tower/step4/:id",
        element: <TowerStep4 />,
      },
      {
        path: "Tower/step5/:id",
        element: <TowerStep5 />,
      },
      {
        path: "Tower/step6/:id",
        element: <TowerStep6 />,
      },
      {
        path: "Tower/step7/:id",
        element: <TowerStep7 />,
      },
      {
        path: "Chiller/step1/:id",
        element: <ChillerStep1 />,
      },
      {
        path: "Chiller/step2/:id",
        element: <ChillerStep2 />,
      },
      {
        path: "Chiller/step3/:id",
        element: <ChillerStep3 />,
      },
      {
        path: "Chiller/step31/:id",
        element: <ChillerStep31 />,
      },
      {
        path: "Chiller/step4/:id",
        element: <ChillerStep4 />,
      },
      {
        path: "Chiller/step41/:id",
        element: <ChillerStep41 />,
      },
      {
        path: "Chiller/step42/:id",
        element: <ChillerStep42 />,
      },
      {
        path: "Chiller/step5/:id",
        element: <ChillerStep5 />,
      },
      {
        path: "Chiller/step6/:id",
        element: <ChillerStep6 />,
      },
      {
        path: "Chiller/step7/:id",
        element: <ChillerStep7 />,
      },
      {
        path: "Chiller/step8/:id",
        element: <ChillerStep8 />,
      },
      {
        path: "Coils/step1/:id",
        element: <CoilsStep1 />,
      },
      {
        path: "Coils/step2/:id",
        element: <CoilsStep2 />,
      },
      {
        path: "Coils/step3/:id",
        element: <CoilsStep3 />,
      },
      {
        path: "Coils/step4/:id",
        element: <CoilsStep4 />,
      },
      {
        path: "static/step1/:id",
        element: <StaticStep1 />,
      },
      {
        path: "static/step2/:id",
        element: <StaticStep2 />,
      },
      {
        path: "static/step3/:id",
        element: <StaticStep3 />,
      },
      {
        path: "BoilerTest/step5/:id",
        element: <BoilerStep5 />,
      },
      {
        path: "BoilerTest/:step/:id",
        element: <BoilerTest />,
      },
      {
        path: "headSheetSystem/step1/:id",
        element: <HeadSheetStep1 />,
      },
      {
        path: "headSheetSystem/step2/:id",
        element: <HeadSheetStep2 />,
      },
      {
        path: "headSheetSystem/step3/:id",
        element: <HeadSheetStep3 />,
      },
      {
        path: "headSheetSystem/step4/:id",
        element: <HeadSheetStep4 />,
      },
      {
        path: "headSheetSystem/step5/:id",
        element: <HeadSheetStep5 />,
      },
      {
        path: "headSheetSystem/step6/:id",
        element: <HeadSheetStep6 />,
      },
      {
        path: "headSheetArray/step1/:id",
        element: <HeadSheetStep1 />,
      },
      {
        path: "headSheetArray/step2/:id",
        element: <HeadSheetStep2 />,
      },
      {
        path: "headSheetArray/step3/:id",
        element: <HeadSheetStep3 />,
      },
      {
        path: "headSheetArray/step4/:id",
        element: <HeadSheetStep4 />,
      },
      {
        path: "headSheetArray/step5/:id",
        element: <HeadSheetStep5 />,
      },
      {
        path: "headSheetArray/step6/:id",
        element: <HeadSheetStep6 />,
      },
      {
        path: "pumpReport/step1/:id",
        element: <PumpStep1 />,
      },
      {
        path: "pumpReport/step2/:id",
        element: <PumpStep2 />,
      },
      {
        path: "pumpReport/step3/:id",
        element: <PumpStep3 />,
      },
      {
        path: "pumpReport/step4/:id",
        element: <PumpStep4 />,
      },
      {
        path: "pumpReport/step5/:id",
        element: <PumpStep5 />,
      },
      {
        path: "pumpReport/step6/:id",
        element: <PumpStep6 />,
      },
      {
        path: "EnergyStudyAirHydronics/step1/:id",
        element: <EnergyHydronicsStep1 />,
      },
      {
        path: "EnergyStudyAirHydronics/step2/:id",
        element: <EnergyHydronicsStep2 />,
      },
      {
        path: "EnergyStudyAirHydronics/step3/:id",
        element: <EnergyHydronicsStep3 />,
      },
      {
        path: "EnergyStudyAirHydronics/step4/:id",
        element: <EnergyHydronicsStep4 />,
      },
      {
        path: "EnergyStudyAirHydronics/step5/:id",
        element: <EnergyHydronicsStep5 />,
      },
      {
        path: "EnergyStudyAirHydronics/step6/:id",
        element: <EnergyHydronicsStep6 />,
      },
      {
        path: "EnergyStudyAirHydronics/step7/:id",
        element: <EnergyHydronicsStep7 />,
      },
    ],
  },
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "glossary",
        element: <NavBarGlossary />,
      },
      {
        path: "recycleBin",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <NavBarRecycleBin />
          </AuthGuard>
        ),
      },
      {
        path: "companies",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Companies]}>
            <Companies />
          </AuthGuard>
        ),
      },
      {
        path: "companies/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Companies]}>
            <Companies />
          </AuthGuard>
        ),
      },
      {
        path: "company/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Company_Edit]}
          >
            <Company />
          </AuthGuard>
        ),
      },
      {
        path: "company/view/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Company_Edit]}
          >
            <CompanyView />
          </AuthGuard>
        ),
      },
      {
        path: "companyDocuments/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Company_Documents]}
          >
            <CompanyDocumentPage />
          </AuthGuard>
        ),
      },
      {
        path: "Administrators",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Administrators]}
          >
            <Administrators />,
          </AuthGuard>
        ),
      },
      {
        path: "Administrators/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Administrators]}
          >
            <Administrator />,
          </AuthGuard>
        ),
      },
      {
        path: "SystemAdministrators",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.System_Administrators]}
          >
            <SystemAdministratorsPage />
          </AuthGuard>
        ),
      },
      {
        path: "Buildings/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <Building />
          </AuthGuard>
        ),
      },
      {
        path: "Working",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ActiveProjects />
          </AuthGuard>
        ),
      },
      {
        path: "Working/Complete",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <TechnicianCompleteProjects />
          </AuthGuard>
        ),
      },
      {
        path: "projectDashboard/:projectId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "templates",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Templates]}>
            <ProjectTemplate />
          </AuthGuard>
        ),
      },
      {
        path: "tolerances/:template",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Templates]}>
            <ProjectTolerances />
          </AuthGuard>
        ),
      },
      {
        path: "templateStudies/:template",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Templates]}>
            <ProjectTemplateStudies />
          </AuthGuard>
        ),
      },
      {
        path: "sampleReports",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Templates]}>
            <SampleReports />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingPhoto/:id/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingPhoto />
          </AuthGuard>
        ),
      },
      {
        path: "Building/:id/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingForm />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingInfectProject/:id/:companyId/:isInfect",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingForm />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingEnergyCost/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingEnergyCost />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingLimitedData/:id/:energyCostProcessId/:type",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingLimitedData />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingAnnualDetailed/:id/:energyCostProcessId/:type",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingAnnualDetailed />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingAnnualDetailedData/:id/:energyCostProcessId/:type",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingAnnualDetailedData />
          </AuthGuard>
        ),
      },
      {
        path: "Building/:id/:companyId/:parentBuildingId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Building]}>
            <BuildingForm />
          </AuthGuard>
        ),
      },
      {
        path: "users/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Users]}>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: "usersModify/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Users]}>
            <AddNewUser />
          </AuthGuard>
        ),
      },
      {
        path: "user/:id/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Users]}>
            <User />
          </AuthGuard>
        ),
      },
      {
        path: "profile",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[""]}>
            <Profile />
          </AuthGuard>
        ),
      },
      {
        path: "emailBlackList",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Email_Black_list]}
          >
            <EmailBlackList />
          </AuthGuard>
        ),
      },
      {
        path: "conversions",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Conversions]}
          >
            <Conversions />
          </AuthGuard>
        ),
      },
      //
      {
        path: "Notifications",
        element: <NotificationsPage />,
      },
      {
        path: "coupons",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Coupons]}>
            <Coupons />
          </AuthGuard>
        ),
      },
      {
        path: "projectsOutForBid",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Projects_out_for_bid]}
          >
            <ProjectsOutForBid />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectOutToBidSysAdmin",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.SysAdmin]}>
            <ProjectsOutForBidAdmin />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectArchitect/:companyId/:tabPage",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[
              Permission.Architect,
              Permission.ArchitectAux,
              Permission.SuperAdministrator,
            ]}
          >
            <ProjectsArchitect />
          </AuthGuard>
        ),
      },
      {
        path: "projectsOutForBid/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Projects_out_for_bid]}
          >
            <ProjectsOutForBidInformation />
          </AuthGuard>
        ),
      },
      {
        path: "projectsOutForBid/ProjectReport/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Projects_out_for_bid]}
          >
            <ProjectReport />
          </AuthGuard>
        ),
      },
      {
        path: "sysAdminDocuments",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.AMP_Documents]}
          >
            <SysAdminDocuments />
          </AuthGuard>
        ),
      },
      {
        path: "TrainingCourses/:catalog",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.TrainingCoursesCatalog]}
          >
            <Catalog />
          </AuthGuard>
        ),
      },
      {
        path: "TechnicianQuizCategory/:catalog",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Quiz]}>
            <Catalog />
          </AuthGuard>
        ),
      },
      {
        path: "Catalog/:catalog",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <Catalog />
          </AuthGuard>
        ),
      },
      {
        path: "UserDocuments/:id/:companyId/:redirect",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Users_Documents]}
          >
            <UploadDocuments />
          </AuthGuard>
        ),
      },
      {
        path: "Contractors",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Architect, Permission.ArchitectAux]}
          >
            <ContractorsList />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Wallet]}>
            <WalletList />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet/Card/:cardId/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Wallet]}>
            <CardForm />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet/totalSummary",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Wallet_Admin_Center]}
          >
            <SystemWalletSummary />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet/elavonPaymentLog",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Wallet_Admin_Center]}
          >
            <ElavonPaymentLog />
          </AuthGuard>
        ),
      },
      {
        path: "Wallet/purchaseHistory",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Wallet_Admin_Center]}
          >
            <PurchaseHistoryLog />
          </AuthGuard>
        ),
      },
      {
        path: "WalletHistory/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Wallet_Admin_Center]}
          >
            <WalletHistory />
          </AuthGuard>
        ),
      },
      {
        path: "plaqueProviders",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Plaque_Decals]}
          >
            <Providers />
          </AuthGuard>
        ),
      },
      {
        path: "provider/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Plaque_Decals]}
          >
            <Provider />
          </AuthGuard>
        ),
      },
      {
        path: "plaques/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Plaque_Decals]}
          >
            <Plaques />
          </AuthGuard>
        ),
      },
      {
        path: "plaqueOrders",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.PlaqueDecalsOrders]}
          >
            <Orders />
          </AuthGuard>
        ),
      },
      {
        path: "viewOrder/:id/:page",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.CompanyOwner]}
          >
            <PlaqueOrdersModify />
          </AuthGuard>
        ),
      },
      {
        path: "plaqueOrdersModify/:id",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.PlaqueDecalsOrders]}
          >
            <PlaqueOrdersModify />
          </AuthGuard>
        ),
      },
      {
        path: "YoutubeTrainingVideos",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <YoutubeVideosBase />
          </AuthGuard>
        ),
      },
      {
        path: "SystemCostsCatalog",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <SubscriptionCost />
          </AuthGuard>
        ),
      },
      {
        path: "Units",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <Units />
          </AuthGuard>
        ),
      },
      {
        path: "TermsAndConditions",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <TermsAndConditions />
          </AuthGuard>
        ),
      },
      {
        path: "TermsAndConditions/:id/:type",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <TermsAndConditionsForm />
          </AuthGuard>
        ),
      },
      {
        path: "MandatoryMinimum",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <MandatoryMinimum />
          </AuthGuard>
        ),
      },
      {
        path: "MandatoryMinimum/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <MandatoryMinimumForm />
          </AuthGuard>
        ),
      },
      {
        path: "CompanyAssetsType",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <CompanyAssetsType />
          </AuthGuard>
        ),
      },
      {
        path: "BuildingSubtype",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <BuildingSubtype />
          </AuthGuard>
        ),
      },
      {
        path: "LocalUnions",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Catalogs]}>
            <LocalUnions />
          </AuthGuard>
        ),
      },
      {
        path: "quiz",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Quiz]}>
            <Quizs />
          </AuthGuard>
        ),
      },
      {
        path: "exam",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Exam]}>
            <Exam />
          </AuthGuard>
        ),
      },
      {
        path: "quiz/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Quiz]}>
            <Quiz />
          </AuthGuard>
        ),
      },
      {
        path: "quizView/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Quiz]}>
            <QuizView />
          </AuthGuard>
        ),
      },
      {
        path: "questions",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Quiz]}>
            <Questions />
          </AuthGuard>
        ),
      },
      {
        path: "pricing",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Subscriptions]}
          >
            <SubscriptionPricing />
          </AuthGuard>
        ),
      },
      {
        path: "detail",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Subscriptions]}
          >
            <SubscriptionDetail />
          </AuthGuard>
        ),
      },
      {
        path: "tickets",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Assign_Support_Tickets]}
          >
            <TicketManagment />
          </AuthGuard>
        ),
      },
      {
        path: "invalidPermissions",
        element: <PageInvalidPermissions />,
      },
      {
        path: "storyBook",
        element: <StoryBookPage />,
      },
      {
        path: "SystemCostsHistory",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryCostCatalog]}
          >
            <History />
          </AuthGuard>
        ),
      },
      {
        path: "couponsWalletMovement",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryCoupon]}
          >
            <CouponsHistroy />
          </AuthGuard>
        ),
      },
      {
        path: "techniciansHistory/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryAuditorsTechnicians]}
          >
            <TechniciansHistory />
          </AuthGuard>
        ),
      },
      {
        path: "projectHistory/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryProject]}
          >
            <ProjectHistory />
          </AuthGuard>
        ),
      },
      {
        path: "studiesHistory/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryReports]}
          >
            <StudyHistory />
          </AuthGuard>
        ),
      },
      {
        path: "userAccessHistory/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryUsersAccess]}
          >
            <UserAccessHistory />
          </AuthGuard>
        ),
      },
      {
        path: "testimonialHistory",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Testimonials]}
          >
            <Testimonials />
          </AuthGuard>
        ),
      },
      {
        path: "forwardDocumentLibrary",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.HistoryDocumentsNotifications]}
          >
            <ForwardDocumentLibrary />
          </AuthGuard>
        ),
      },
      {
        path: "projectHistoryBid/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Project_Bid_History]}
          >
            <ProjectBidsHistory />
          </AuthGuard>
        ),
      },
      {
        path: "auditorDashboard",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Auditor]}>
            <AuditorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "energyAuditorDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.EnergyAuditor]}
          >
            <AuditorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "temporalEnergyAuditorDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.TemporalEnergyAuditor]}
          >
            <AuditorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "temporalAuditorDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.TemporalAuditor]}
          >
            <AuditorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "architectDashboard",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Architect]}>
            <ArchitectDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "companyAdministratorsDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[
              Permission.CompanyOwner,
              Permission.Administrator,
              Permission.ProjectManager,
            ]}
          >
            <CompanyOwnerDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "superAdministratorDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.SuperAdministrator]}
          >
            <SuperAdministratorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "unionAdministratorDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.UnionAdministrator]}
          >
            <UnionAdministratorDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "trainerDashboard",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Trainer]}>
            <TrainerDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "trainingDashboard",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Training]}>
            <TrainingDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "projectManagerDashboard",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectManager]}
          >
            <ProjectManagerDashboard />
          </AuthGuard>
        ),
      },
      {
        path: "sysAdminDashboard",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.SysAdmin]}>
            <SysAdminDashboar />
          </AuthGuard>
        ),
      },
      {
        path: "UserDocuments/:userId/:companyId/1",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Users_Documents]}
          >
            <PersonalDocuments />
          </AuthGuard>
        ),
      },
      {
        path: "companyAssets/:companyId/:asset",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Equipment]}>
            <Assets />
          </AuthGuard>
        ),
      },
      {
        path: "companyAssets/:companyId/:asset",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Vehicles]}>
            <Assets />
          </AuthGuard>
        ),
      },
      {
        path: "companyAssetsDocument/:assetId/:companyId/:asset",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Users_Documents]}
          >
            <AssetsDocumentation />
          </AuthGuard>
        ),
      },
      {
        path: "companyAssets/Edit/:assetId/:companyId/:asset/:type",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Company_Assets]}
          >
            <AddUpdateEquipmentVehicle />
          </AuthGuard>
        ),
      },
      //Common
      {
        path: "DocumentLibrary/:projectId/:companyId/:returnPage",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Users_Documents]}
          >
            <DocumentLibrary />
          </AuthGuard>
        ),
      },
      {
        path: "PhotoLibrary/:projectId/:companyId/:returnPage",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Users_Documents]}
          >
            <PictureLog />
          </AuthGuard>
        ),
      },
      //
      {
        path: "teamMembers/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.TeamMembers]}
          >
            <TeamMembers />
          </AuthGuard>
        ),
      },
      {
        path: "TrainingDocuments/",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.TrainingDocuments]}
          >
            <TrainingDocuments />
          </AuthGuard>
        ),
      },
      {
        path: "Project/Edit/:projectId/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <Project />
          </AuthGuard>
        ),
      },
      {
        path: "TemporalProjectContest/:id",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <TemporalProjectContest />
          </AuthGuard>
        ),
      },
      {
        path: "Project/CreateInfectious",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <CreateInfectiousControlProject />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectStudies/:projectId/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectStudies />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectStudies/:projectId/:companyId/:navRoute",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectStudies />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectList/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectList />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectList/:tabName/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectList />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectEvaluation",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjecEvaluation]}
          >
            <ProjectEvaluation />
          </AuthGuard>
        ),
      },
      {
        path: "plaqueCompanyOrders",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <PlaqueCompanyOrders />
          </AuthGuard>
        ),
      },
      {
        path: "purchase/storage",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.PurchaseStorage]}
          >
            <PurchaseStorage />
          </AuthGuard>
        ),
      },
      {
        path: "upgradeSubscription",
        element: <UpgradeSubscription />,
      },
      {
        path: "Technicians/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Technicians]}
          >
            <AuditorTechnicians />
          </AuthGuard>
        ),
      },
      {
        path: "TechniciansAssignments/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Technicians]}
          >
            <AuditorTechniciansAssignmentss />
          </AuthGuard>
        ),
      },

      {
        path: "TechnicianModify/:userId/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Technicians]}
          >
            <AuditorTechnicianEditAdd />
          </AuthGuard>
        ),
      },
      {
        path: "UserPermissions/:userId/:companyId/:techType",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Users]}>
            <TechniciansPermissions />
          </AuthGuard>
        ),
      },
      {
        path: "cart",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Cart]}>
            <Cart />
          </AuthGuard>
        ),
      },
      {
        path: "storageSummary",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.PurchaseStorage]}
          >
            <MemorySummary />
          </AuthGuard>
        ),
      },
      {
        path: "suggestionBank",
        element: <SuggestionBankList />,
      },
      {
        path: "suggestionBankAdmin",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Commitee_Suggestion_Box]}
          >
            <SuggestionBankListAdmin />
          </AuthGuard>
        ),
      },
      {
        path: "suggestionBankInfo/:id/:statusId",
        element: <SuggestionBankInfo />,
      },
      {
        path: "selectPlaque/:projectId/:type/:page",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Project_Admin]}
          >
            <SelectPlaques />
          </AuthGuard>
        ),
      },
      {
        path: "deliveryAddress/:projectId/:page",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Project_Admin]}
          >
            <DeliveryAddress />
          </AuthGuard>
        ),
      },
      {
        path: "completeOrder/:projectId/:page",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.Project_Admin]}
          >
            <CompleteOrder />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectWizard/Edit/:projectId/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ProjectWizard />
          </AuthGuard>
        ),
      },
      {
        path: "TolerancesWizard/Edit/:projectId/:companyId",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Project]}>
            <ToleranceWizard />
          </AuthGuard>
        ),
      },
      {
        path: "StudiesReport/Edit/:projectId/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectContest]}
          >
            <StudiesWizard />
          </AuthGuard>
        ),
      },
      {
        path: "ConfigurationWizard/Edit/:projectId/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectContest]}
          >
            <ConfigurationWizard />
          </AuthGuard>
        ),
      },
      {
        path: "ProjectContractors/Edit/:id/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectContest]}
          >
            <ProjectOutForBidWizard />
          </AuthGuard>
        ),
      },
      {
        path: "ViewTemplateFromTolerances/:projectId/:templateId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectContest]}
          >
            <ViewTemplateFromTolerances />
          </AuthGuard>
        ),
      },

      {
        path: "ProjectContractors/:id/:companyId",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.ProjectContest]}
          >
            <ProjectsOutForBidInformation />
          </AuthGuard>
        ),
      },
      {
        path: "dispute/:projectId/",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Dispute]}>
            <Dispute />
          </AuthGuard>
        ),
      },
      {
        path: "infectiousProject/:companyId/",
        element: (
          <AuthGuard permission={-1} permissionMatrix={[Permission.Architect]}>
            <ProjectInfectiousControlArchitect />
          </AuthGuard>
        ),
      },
      {
        path: "MyPendingRequests/:companyId/",
        element: (
          <AuthGuard
            permission={-1}
            permissionMatrix={[Permission.My_Pending_Requests]}
          >
            <MyPendingRequests />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "contactInformation",
    element: <ContactInformationMain />,
  },
  {
    path: "contactConfirmation/:code",
    element: <ContactConfirmation />,
  },
  {
    path: "confirmTeamMember/:code",
    element: <ConfirmTeamMember />,
  },
  {
    path: "ResetPassword/:code/:email",
    element: <ResetPasswordUser />,
  },
  {
    path: "ResetPasswordNewUser/:code/:email",
    element: <NewAccountPassword />,
  },
  {
    path: "ResetPasswordNewUserCompany/:code/:email",
    element: <NewAccountPassword />,
  },
  {
    path: "RequestTransferCompanyAssets/:code",
    element: <RequestTransferCompanyAssets />,
  },
  {
    path: "Memorandum/:code",
    element: <RequestMemorandum />,
  },
  {
    path: "ChangeOrder/:code",
    element: <RequestWorkOrder />,
  },
  {
    path: "sharedDocuments/:code",
    element: <GetDocument />,
  },
  {
    path: "RequestTransferTechnician/:code",
    element: <TechnicianTransferRequest />,
  },
  {
    path: "AuthorizeTechnicianPermission/:code",
    element: <AuthorizeTechnicianPermission />,
  },
  {
    path: "RatingContractorNotification/:code",
    element: <RatingContractorNotification />,
  },
  {
    path: "SurveyProject/:code",
    element: <SurveyProject />,
  },
  {
    path: "unsubscribe/:guid/:key",
    element: <Unsubscribe />,
  },
  {
    path: "private",
    element: (
      <AuthGuard
        permission={RoleEnum.SuperAdministrator + RoleEnum.SysAdmin}
        permissionMatrix={[""]}
      >
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
